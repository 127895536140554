import React from 'react';

const CheckProcessing = () => {
    return (
        <div>
            <p>Your license is ready! you can start work. access to your CRM system will be opened within 48 hours</p>
        </div>
    );
};

export default CheckProcessing;