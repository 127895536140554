import React from 'react';

const Merchants = () => {
    return (
        <div>
            Merchants
        </div>
    );
};

export default Merchants;