import React, {useState} from 'react';

const Check = () => {
    const wallet = 'TWssGnvQ5o8LzbanBaijAFSxqvfe89uURX'
    const [copyText, setCopyText] = useState('Click to copy')

    const copyWallet = () => {
        navigator.clipboard.writeText(wallet)
        setCopyText('Copied!')
        setTimeout(() => {
            setCopyText('Click to copy')
        }, 1000)
    }

    return (
        <div className={'check'} style={{
            background: '#e3e3e3',
            borderRadius: '0.2857rem',
            padding: '1rem 2rem',
            color: '#8585ff',
            fontWeight: 'bold',
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            marginTop: '1rem',
            cursor: 'pointer'
        }} onClick={copyWallet}>
            <span className="help-text">{copyText}</span>
            <span className={'wallet'}>{wallet}</span>
        </div>
    );
};

export default Check;